import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'option']

  toggleItem(event) {
    if (isChecked(event.currentTarget)) {
      this.deselectItem(event)
    } else {
      this.selectItem(event)
    }
  }

  selectItem(event) {
    if (this.hasInputTarget) {
      const item = event.currentTarget

      this.optionTargets.forEach((option) => removeSelectionAffordance(option))
      addSelection(item, this.inputTarget)
    }
  }

  deselectItem(event) {
    if (this.hasInputTarget) {
      const item = event.currentTarget

      removeSelection(item, this.inputTarget)
    }
  }
}

const addSelection = (item, inputTarget) => {
  inputTarget.value = item.dataset.value
  item.classList.add('checked')
}
const isChecked = (element) => element.classList.contains('checked')

const removeSelection = (item, inputTarget) => {
  inputTarget.value = ''
  item.classList.remove('checked')
}

const removeSelectionAffordance = (item) => {
  item.classList.remove('checked')
}

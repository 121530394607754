import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal'];

  close() {
    if (this.hasModalTarget) {
      this.modalTarget.style.display = 'none';
      document.querySelector('.modal-backdrop.fade.in').style.display = 'none';
    }
  }
}

//TODO: won't be able to use it until fix issue that controller is loaded twice
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menuToDisplay', 'trigger']
  static values = { elementClass: Array }

  showMenu(event) {
    if (this.hasMenuToDisplayTarget) {
      const indexToShow = parseInt(event.currentTarget.dataset.menuNumber)

      if (this.lastTarget == undefined) {
        this.menuToDisplayTargets[indexToShow].classList.remove('hidden')
        this.toggleElementClass(indexToShow)
      } else {
        this.menuToDisplayTargets.forEach((element, index) => {
          if (element.classList.contains('hidden')) {
            if (index == indexToShow) {
              element.classList.remove('hidden')
              this.toggleElementClass(index)
            }
          } else {
            element.classList.add('hidden')
            this.toggleElementClass(index, false)
          }
        })
      }
      this.lastTarget = true
    }
  }

  toggleElementClass(index, add = true) {
    if (this.hasElementClassValue && this.hasTriggerTarget) {
      if (add) {
        this.triggerTargets[index].classList.add(...this.elementClassValue)
      } else {
        this.triggerTargets[index].classList.remove(...this.elementClassValue)
      }
    }
  }
}

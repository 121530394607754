import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['dropdown']

  connect() {
    if (!this.hasDropdownTarget) return

    useClickOutside(this, { element: this.dropdownTarget })
  }

  clickOutside(event) {
    if (event.target.id !== 'mega-menu' && !Array.from(event.target.classList).some(
      klass => ['menu-link', 'mega-menu', 'mega-menu-link', 'mega-menu-arrow'].includes(klass))
    ) {
      const divList = document.querySelectorAll('.mega-menu');

      divList.forEach(div => {
        if(!div.classList.contains('hidden')) {
          div.classList.add('hidden')
        }
      })
    }
  }

  removeDisplay(event) {
    if (this.hasDropdownTarget) {
      const currentContainer = event.currentTarget.closest(
        "[data-one-dropdown-target='dropdownContainer']"
      )
      const currentDropdown = currentContainer.querySelector(
        "[data-one-dropdown-target='dropdown']"
      )

      this.dropdownTargets.forEach((target) => {
        if (target !== currentDropdown) {
          target.classList.add('hidden')
        } else {
          target.classList.remove('hidden')
        }
      })
    }
  }
}

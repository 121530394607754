import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const host = this.element
    const template = document.querySelector("#templateContent")
    host.attachShadow({mode:'open'}).appendChild(template.content)

    this.enableAnchorInShadow()
  }

  enableAnchorInShadow() {
    const shadowDom = document.getElementById('shadow-dom').shadowRoot

    this.anchorOnClick(shadowDom)
    this.anchorOnRedirect(shadowDom)
  }

  anchorOnRedirect(shadowDom) {
    const hash = window.top.location.hash.substr(1)

    if (hash) {
      this.scrollToAnchor(shadowDom, hash)
    }
  }

  anchorOnClick(shadowDom) {
    const scrollToAnchor = this.scrollToAnchor

    for (let anchor of shadowDom.querySelectorAll('a[href^="#"]')) {
      $(anchor).click(function() {
        const hash = anchor.getAttribute('href').substr(1)

        scrollToAnchor(shadowDom, hash)
      });
    }
  }

  scrollToAnchor(shadowDom, hash) {
    const target = shadowDom.getElementById(hash)

    if (target) {
      target.scrollIntoView()
    }
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "content", "icon", "elementToFocus", 'requiredField',
                    ,'checkboxContainer', 'checkboxValue'];
  static values = { expandIconPath: String, closeIconPath: String }

  connect(){
    if (this.hasIconTarget && this.hasCloseIconPathValue && !this.iconTarget.src) {
      this.iconTargets.forEach(target => {
        target.src = this.closeIconPathValue;
      });
    }
  }

  toggle(event) {
    if (this.hasContentTarget) this.toggleContent();
    if (this.hasIconTarget) this.toggleIcon(event);
  }

  toggleContent() {
    if (!this.isContentDisplayed()) {
      this.contentTarget.style.display = "block";
      this.hasElementToFocusTarget && this.elementToFocusTarget.focus()

      return
    }

    this.contentTarget.style.display = "none";
  }

  toggleIcon(event) {
    const expandIconPath = `${this.expandIconPathValue}`;
    const closeIconPath = `${this.closeIconPathValue}`;

    if (this.hasContainerTarget) {
      this.iconTarget.src = this.isContentDisplayed()
        ? closeIconPath
        : expandIconPath;
    } else {
      const regexp = /[^/]+$/g
      const currentIcon = event.currentTarget.querySelector("[data-toggable-target='icon']")
      const newIconPath = currentIcon.src.match(regexp)[0] == closeIconPath.match(regexp)[0]
      ? expandIconPath
      : closeIconPath;
      event.currentTarget.querySelector("[data-toggable-target='icon']").src = newIconPath;
      if (this.hasCheckboxContainerTarget) return this.setCheckboxValue(currentIcon, event.currentTarget)
    }
  }

  isContentDisplayed() {
    return this.contentTarget.style.display == "block";
  }

  toggleContainerClass() {
    const selector = "form__playlist";

    if (this.containerTarget.classList.contains(selector)) {
      return this.containerTarget.classList.remove(selector);
    }

    this.containerTarget.classList.add(selector);
  }

  setCheckboxValue(currentIcon, currentTarget) {
    if (this.hasCheckboxValueTarget) {
      const currentCheckbox = currentTarget.closest("[data-toggable-target='checkboxContainer']")
      currentCheckbox.querySelectorAll("[data-toggable-target='icon']").forEach((icon, index) => {
        if (index != parseInt(currentIcon.dataset.iconIndex)) icon.src = `${this.closeIconPathValue}`
      });
      currentCheckbox.querySelector("[data-toggable-target='checkboxValue']").value =
      currentTarget.querySelector("[data-toggable-target='answer']").innerText
    }
  }
}

import { Controller} from "stimulus";

export default class extends Controller {
  reviewCart(e) {
    e.preventDefault()

    const accessCode = e.target.form.children.EWAY_ACCESSCODE.value

    fetch(`/cart/review?AccessCode=${accessCode}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const cart_price = parseFloat(data['cart']['total_with_shipping']).toFixed(2)
        const form_price = (data['eway']['total_amount'] / 100).toFixed(2)

        if (cart_price === form_price) {
          e.target.form.submit()
        }
        else {
          window.location.replace("/cart/review?cart_updated=true");
        }
      })
  }
}

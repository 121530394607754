import { Controller} from "stimulus";

export default class extends Controller {
  static  targets = ['button', 'text']

  disableButton(e) {
    this.buttonTarget.disabled = 'disabled'

    if (this.textTarget.tagName === 'SPAN') {
      this.textTarget.innerHTML = 'Processing...'
    }
    else {
      this.textTarget.value = 'Processing...'
    }
  }
}

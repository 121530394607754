import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'addButton',
    'confirmDelete',
    'cancelDelete',
    'modalityToDelete',
  ]

  async addModality(event) {
    await this.fetchModality(event.currentTarget.dataset.src)
    this.checkModalityButton()
  }

  connect() {
    this.checkModalityButton()
  }

  deleteModality(event) {
    if (this.hasConfirmDeleteTarget && this.hasCancelDeleteTarget) {
      const confirmContainer = this.element.querySelector(
        '.confirm-delete-modality'
      )
      if (this.confirmDeleteTarget == event.currentTarget) {
        this.modalityToDeleteTarget.remove()
        confirmContainer.classList.add('hidden')
        this.checkModalityButton()
      } else if (this.cancelDeleteTarget == event.currentTarget) {
        confirmContainer.classList.add('hidden')
      } else if (confirmContainer.classList.contains('hidden')) {
        confirmContainer.classList.remove('hidden')
        event.currentTarget.parentElement.dataset.modalitiesTarget =
          'modalityToDelete'
      }
    } else {
      event.currentTarget.parentElement.remove()
      this.checkModalityButton()
    }
  }

  async fetchModality(src) {
    const response = await fetch(src)
    const responseHTML = await response.text()

    this.element.querySelector('.modality').innerHTML += responseHTML
  }

  checkModalityButton() {
    if (this.hasAddButtonTarget) {
      const numberOfModalities = this.addButtonTargets.length
      if (numberOfModalities > 2) {
        this.addButtonTargets.forEach((addButton) => {
          addButton.disabled = true
        })
      } else if (numberOfModalities > 1) {
        this.addButtonTargets[0].disabled = true
        this.addButtonTargets[1].disabled = false
      } else {
        this.addButtonTarget.disabled = false
      }
    }
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['requiredField', 'submitInput', 'internationalFields', 'roleSelect'];

  validate() {
    const requiredFieldsValues = this.requiredFieldTargets.map((element) => element.value)
    const hasEmptyValues = requiredFieldsValues.filter(element => !element).length > 0;

    this.setSubmitDisabled(hasEmptyValues);
  }

  setSubmitDisabled(value) {
    const disabledClassName = 'form-disabled'
    this.submitInputTarget.disabled = !!value;

    if (value) {
      return this.submitInputTarget.classList.add(disabledClassName)
    }

    this.submitInputTarget.classList.remove(disabledClassName)
  }

  toggleInternationalFields() {
    if (this.roleSelectTarget.options[this.roleSelectTarget.selectedIndex].text === 'International') {
      this.internationalFieldsTarget.classList.remove('hidden')
    }
    else {
      this.internationalFieldsTarget.classList.add('hidden')
    }
  }
}

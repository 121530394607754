import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['wrapper', 'slide', 'leftControl', 'rightControl']

  connect() {
    this.wrapperTarget.addEventListener('keydown', preventKeyBoardScroll)
    this.wrapperTarget.addEventListener('wheel', preventScroll)
    this.wrapperTarget.addEventListener('touchmove', preventScroll)
    this.wrapperTarget.addEventListener('touchstart', this.setScroll)
    this.wrapperTarget.addEventListener('touchend', this.scrollOnTouch)
    this.wrapperTarget.addEventListener('scroll', this.Onscroll)
    this.setSwiper()
  }

  setScroll = (event) => (this.scrollPosition = event.touches[0].clientX)

  scrollOnTouch = (event) => {
    const newPosition = event.changedTouches[0].clientX
    if (this.scrollPosition > newPosition) this.scrollRight()

    if (this.scrollPosition < newPosition) this.scrollLeft()
  }

  scrollLeft(_event) {
    this.scroll({ left: true })
  }

  scrollRight(_event) {
    this.scroll({ right: true })
  }

  scroll({ left, right }) {
    if (this.clientWidth < this.scrolltWidth) {
      const gapSlide = this.slideWidth + this.calcGap()

      if (left) {
        this.wrapperTarget.scroll({
          left: this.wrapperScroll - gapSlide,
          behavior: 'smooth',
        })
      }
      if (right) {
        this.wrapperTarget.scroll({
          left: this.wrapperScroll + gapSlide,
          behavior: 'smooth',
        })
      }
    }
  }

  Onscroll = (_event) => {
    window.clearTimeout(this.isScrolling)

    this.isScrolling = setTimeout(() => {
      this.setSwiper(null, false)
    }, 66)
  }

  updateControllers() {
    if (this.clientWidth < this.scrolltWidth) {
      this.rightControlTarget.classList.remove('hidden')
      this.leftControlTarget.classList.remove('hidden')
      this.adjustWrapper()

      if (this.wrapperScroll == 0) {
        this.leftControlTarget.classList.add(...this.disabledClasses())
      } else {
        this.leftControlTarget.classList.remove(...this.disabledClasses())
      }
      if (this.clientWidth + this.wrapperScroll >= this.scrolltWidth) {
        this.rightControlTarget.classList.add(...this.disabledClasses())
      } else {
        this.rightControlTarget.classList.remove(...this.disabledClasses())
      }
    } else {
      this.rightControlTarget.classList.add('hidden')
      this.leftControlTarget.classList.add('hidden')
    }
  }

  setSwiper = (_event, resetWidth = true) => {
    if (resetWidth) this.wrapperTarget.style.width = ''

    this.clientWidth = this.wrapperTarget.clientWidth
    this.scrolltWidth = this.wrapperTarget.scrollWidth
    this.slideWidth = this.slideTarget.clientWidth
    this.wrapperScroll = this.wrapperTarget.scrollLeft

    this.updateControllers()
  }

  adjustWrapper() {
    const leftIconWidth = parseFloat(
      window.getComputedStyle(this.leftControlTarget).getPropertyValue('width')
    )
    const rightIconWidth = parseFloat(
      window.getComputedStyle(this.rightControlTarget).getPropertyValue('width')
    )
    const gap = this.calcGap()
    const slidesCount = this.slideTargets.length
    const gapCount = slidesCount - 1

    const wrapperWidth = this.element.clientWidth - leftIconWidth - rightIconWidth

    const gapSlide = this.slideWidth + gap

    const countSlidesAllowed = Math.floor(wrapperWidth / gapSlide)

    const newWrapperWidth =
      countSlidesAllowed == 0 ? gapSlide : countSlidesAllowed * gapSlide

    this.wrapperTarget.style.width = `${newWrapperWidth}px`
  }

  disabledClasses() {
    return ['pointer-events-none', 'text-gray-50', 'cursor-default']
  }

  calcGap() {
    const firstSlide = this.slideTarget.offsetLeft
    const secondSlide = this.slideTargets[1].offsetLeft

    return secondSlide ? secondSlide - (firstSlide + this.slideWidth) : 0
  }
}

const preventKeyBoardScroll = (event) => {
  var keys = [32, 33, 34, 35, 37, 38, 39, 40]
  if (keys.includes(event.keyCode)) {
    event.preventDefault()
    return false
  }
}

const preventScroll = (event) => {
  event.preventDefault()
  event.stopPropagation()

  return false
}

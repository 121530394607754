import { Controller} from "stimulus";

export default class extends Controller {
  static  targets = ['orderId']

    connect() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const accessCode = urlParams.get('AccessCode')
      const orderId = this.orderIdTarget.dataset['orderId']

      setTimeout(
        window.location.replace(`/carts/${orderId}/payments/process_order?AccessCode=${accessCode}`),
        2000
      )
    }
}

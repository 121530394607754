import { Controller} from "stimulus";

export default class extends Controller {
  static targets = ['checkbox', 'field', 'input']

  connect() {
    this.toggleVisibility()
  }

  toggleVisibility() {
    if (this.checkboxTarget.checked) {
      this.fieldTarget.classList.remove('hide')
    }
    else {
      this.fieldTarget.classList.add('hide')

      for (let checkbox of this.fieldTarget.getElementsByTagName("input")) {
        checkbox.checked = false
      }
    }
  }
}

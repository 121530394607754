import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toUndisable', 'triggerElement']

  connect() {
    if (
      this.hasToUndisableTarget &&
      this.hasTriggerElementTarget &&
      this.toUndisableTarget.disabled == true
    ) {
      this.next = true
      this.elementChanges = []
      this.initialValues = this.triggerElementTargets.map((el, index) => {
        el.dataset.index = index
        if (el.dataset.needed) this.areDependencies = true
        this.elementChanges.push({
          change: false,
          needed: el.dataset.needed ? true : undefined,
        })

        return {
          value: el.value,
          needed: el.dataset.needed ? true : undefined,
        }
      })
    } else {
      this.next = false
    }
  }

  //TODO: refactor
  checkChange(event) {
    if (this.next) {
      const target = event.currentTarget
      const dataTarget = event.currentTarget.dataset
      const targetIndex = parseInt(dataTarget.index)
      if (
        this.hasToUndisableTarget &&
        dataTarget.undisableButtonTarget == 'triggerElement'
      ) {
        if (target.value != this.initialValues[targetIndex].value) {
          this.elementChanges[targetIndex].change = true
          if (this.areDependencies && this.needsDependingToChange(dataTarget)) {
            this.toUndisableTarget.disabled = true
          } else {
            this.toUndisableTarget.disabled = false
          }
        } else {
          this.elementChanges[targetIndex].change = false
          if (
            this.areNotAnyChanges(targetIndex) ||
            (this.areDependencies && this.hasDependandChange(dataTarget))
          ) {
            this.toUndisableTarget.disabled = true
          } else {
            this.toUndisableTarget.disabled = false
          }
        }
      }
    }
  }

  spliciedArray(array, index) {
    const copiedArray = [...array]
    copiedArray.splice(index, 1)
    return copiedArray
  }

  hasDependandChange(dataset) {
    if (dataset.needed) {
      return this.spliciedArray(this.elementChanges, dataset.index).some(
        (el, i) => {
          return el.needed && el.change
        }
      )
    } else {
      return !(
        this.elementChanges.filter((el) => el.needed && el.change).length ==
          this.initialValues.filter((el) => el.needed).length ||
        this.elementChanges.filter((el) => el.needed && !el.change).length ==
          this.initialValues.filter((el) => el.needed).length
      )
    }
  }

  needsDependingToChange(dataset) {
    if (dataset.needed) {
      return this.spliciedArray(this.elementChanges, dataset.index).some(
        (el, i) => {
          return el.needed && !el.change
        }
      )
    } else {
      return (
        this.elementChanges.filter((el) => el.needed && el.change == false)
          .length != this.initialValues.filter((el) => el.needed).length &&
        this.elementChanges.filter((el) => el.needed && el.change).length !=
          this.initialValues.filter((el) => el.needed).length
      )
    }
  }

  areNotAnyChanges(index) {
    return !this.spliciedArray(this.elementChanges, index).some(
      (el) => el.change
    )
  }
}

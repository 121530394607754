import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["currentChapter"]
  static values = { canRead: Boolean }

  connect() {
    $('.banner--webinar').hide()
    let _this = this;
    this.embedVimeo = this.element.querySelector('#embed-vimeo')
    this.embedYoutube = this.element.querySelector('#embed-yt')
    if (this.embedVimeo || this.embedYoutube) {
      const chapters = this.element.querySelectorAll('.btn--video-chapter')
      _this.chaptersStart = []
      _this.currentVideoTarget = this.hasCurrentChapterTarget ? this.currentChapterTarget.innerText : 0

      chapters.forEach((chapter, index) => {
        _this.chaptersStart.push({seconds: chapter.dataset.seconds, index: index + 1})
      })

      this.checkSetVideoApi()
    }
  }

  between(num, min, max) {
      return num >= min && num <= max
  }

  changeCurrentChapter(currentChapter) {
    if (this.hasCurrentChapterTarget) this.currentChapterTarget.innerText = currentChapter
  }

  checkSetCurrentChapter(currentChapter, chaptersStart, currentSeconds, duration) {
    if (chaptersStart.length > 1) {
      chaptersStart.forEach((chapter, index) => {
        if (index == 0 && currentSeconds < chapter.seconds) {
          this.changeCurrentChapter(1)
        } else {
          if (index < chaptersStart.length - 1) {
            if (this.between(currentSeconds + 1, chapter.seconds , chaptersStart[index + 1].seconds)
               && currentChapter != chapter.index) {
              this.changeCurrentChapter(chapter.index)
            }
          } else {
            if (this.between(currentSeconds, chapter.seconds , duration)
               && currentChapter != chapter.index) {
              this.changeCurrentChapter(chapter.index)
            }
          }
        }
      })
    } else {
      this.changeCurrentChapter(1)
    }
  }

  setVideoTime(event) {
    const seconds = parseInt(event.currentTarget.dataset.seconds)
    const minutes = parseInt(event.currentTarget.dataset.minutes)
    let timeToSet = seconds + minutes * 60
    if (this.embedVimeo) {
      window.vimeoPlayer.setCurrentTime(timeToSet);
      this.checkSetCurrentChapter(this.currentVideoTarget, this.chaptersStart, timeToSet, this.duration)
    } else if (this.embedYoutube && window.youtubePlayer) {
      window.youtubePlayer.seekTo(timeToSet, true);
      this.checkSetCurrentChapter(this.currentVideoTarget, this.chaptersStart, timeToSet, this.duration)
    }
  }

   checkSetVideoApi() {
    if (this.embedVimeo) {
      this.setVimeo(this.embedVimeo)
    } else if (this.embedYoutube) {
      this.setYoutube(this.embedYoutube)
    }
  }

  cpeActions(end = false) {
    $('.cpe-actions--message').remove()
    if (end) {
      $('.cpe--get').addClass('hidden')
    }else{
      $('.cpe--get').addClass('cpe--ready pointer-events-none')
    }
  }

  async setVimeo(embedVimeo){
    let _this = this
    const videoUrl = embedVimeo.getAttribute('data-video-url');
    const videoProgressSeconds = embedVimeo.getAttribute('data-video-progress-seconds') || 0;
    const eresourceId = embedVimeo.getAttribute('data-eresource-id');

    const vimeoOptions = {
      url: videoUrl,
    };
    let currentVideoSeconds;
    window.vimeoPlayer = new Vimeo.Player(embedVimeo, vimeoOptions)
    this.duration = await window.vimeoPlayer.getDuration()

    window.vimeoPlayer.setCurrentTime(videoProgressSeconds)

    const currentProgress = parseInt(embedVimeo.dataset.videoProgressSeconds)
    this.checkSetCurrentChapter(this.currentVideoTarget, this.chaptersStart, currentProgress, this.duration)

    if (currentProgress >= this.duration - 300) {
      $('#eresource-questions').removeClass('hidden')
      this.cpeActions(true)
    }

    window.vimeoPlayer.on('timeupdate', (data) => {
      this.checkSetCurrentChapter(this.currentVideoTarget, this.chaptersStart, data.seconds, data.duration)
    })

    window.vimeoPlayer.on('ended', () => {
      $('#eresource-questions').removeClass('hidden')
      this.cpeActions(true)
    })

    window.vimeoPlayer.on('timeupdate', (data) => {
      currentVideoSeconds = data.seconds

      if (currentVideoSeconds >= this.duration - 300) {
        $('#eresource-questions').removeClass('hidden')
        this.cpeActions(true)
      }
    })

    window.vimeoPlayer.on('timeupdate', (data) => {
      currentVideoSeconds = data.seconds

      if (!_this.canReadValue) {
        window.vimeoPlayer.unload()
        $('.banner--webinar').show()
      }
    })

    if (!_this.canReadValue) {
      window.vimeoPlayer.on('ended', (data) => {
        $('.banner--webinar').show()
      })
    }

    $(window).on("beforeunload", function() {
      if (_this.canReadValue && currentVideoSeconds) {
        $.ajax({
          type: "PUT",
          url: `/video_progresses/${eresourceId}`,
          data: {
            video_progress: {
              seconds: currentVideoSeconds,
              remaining_seconds: _this.duration - currentVideoSeconds
            }
          },
          dataType: 'json'
        });
      }
    })
  }

  setYoutube() {
    let _this = this
    if (typeof YT === 'undefined') {
      const youtubeScript = document.createElement('script');
      youtubeScript.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(youtubeScript, firstScriptTag);
    }
    window.youtubePlayer = {};
    window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
      if (typeof YT === 'undefined') return;

      $('.youtube-player').each(function() {
        let div = $(this);
        _this.idPlayer = "embed-yt"
        var eresourceId = div.attr('data-eresource-id');
        var startSeconds = parseInt(div.attr('data-video-progress-seconds'));

        let videotime;

        window.youtubePlayer = window.youtubePlayer;

        window.youtubePlayer = new YT.Player(_this.idPlayer, {
          videoId: div.data('youtube'),
          playerVars: {
            controls: 1,
            modestbranding: 1,
            showinfo: 0,
            rel: 0,
          },
          events: {
            'onReady': async (event) => {
              _this.duration = await window.youtubePlayer.getDuration()

              if (startSeconds) {
                event.target.seekTo(startSeconds)
                _this.checkSetCurrentChapter(_this.currentVideoTarget, _this.chaptersStart, startSeconds, _this.duration)
                if (startSeconds >= _this.duration - 300) {
                  $('#eresource-questions').removeClass('hidden')
                  _this.cpeActions(true)
                }
              }
            },
            'onStateChange': (event) => {
              if (_this.canReadValue) {
                if (event.data === YT.PlayerState.ENDED) {
                  $('#eresource-questions').removeClass('hidden')
                  _this.cpeActions(true);
                  $('.cpe-actions--message').remove()
                  if (_this.timeupdater) clearInterval(_this.timeupdater)
                } else if (event.data === YT.PlayerState.PLAYING) {

                  function updateTime() {
                    let oldTime = videotime;
                    if(window.youtubePlayer  && window.youtubePlayer.getCurrentTime) {
                      videotime = window.youtubePlayer.getCurrentTime();
                    }
                    if(videotime !== oldTime) {
                      _this.checkSetCurrentChapter(_this.currentVideoTarget, _this.chaptersStart, videotime, _this.duration)
                    }
                  }
                  _this.timeupdater = setInterval(updateTime, 100);
                } else {
                  if (_this.timeupdater) clearInterval(_this.timeupdater)
                }
              } else {
                window.youtubePlayer.stopVideo()
                $('.banner--webinar').show()
              }
            },
          }
        });

        $(window).on("beforeunload", async function() {
          const seconds = window.youtubePlayer.getCurrentTime() || 0
          const duration = _this.duration || await window.youtubePlayer.getDuration()

          $.ajax({
            type: "PUT",
            url: `/video_progresses/${eresourceId}`,
            data: {
              video_progress: {
                seconds: seconds,
                remaining_seconds: duration - seconds
              }
            },
            dataType: 'json'
          });
        });
      });
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container', 'absoluteContainer']

  connect() {
    if (this.hasContainerTarget) {
      const backgroundColor = this.containerTarget.dataset.backgroundColor
      if (backgroundColor)
        this.addBackGroundcolor(this.element, backgroundColor)
    }

    if (this.hasAbsoluteContainerTarget) {
      this.absoluteContainerTargets.forEach((element) => {
        const innerHeight = element.clientHeight
        this.createAbsoluteWraper(innerHeight, element)
      })
    }
  }

  addBackGroundcolor(element, classColor, styleColor) {
    if (classColor) element.classList.add(classColor)
    if (styleColor) element.style.backgroundColor = styleColor
  }

  createAbsoluteWraper(wrapperHeight, target) {
    const self = this
    const mainWrapper = document.createElement('div')
    const absoluteWrapper = document.createElement('div')
    const customWrapper = document.createElement('div')
    absoluteWrapper.classList.add('absolute', 'left-0', 'w-full')
    customWrapper.classList.add('site-container')
    const backgroundColor = window
      .getComputedStyle(target, null)
      .getPropertyValue('background-color')
    self.addBackGroundcolor(absoluteWrapper, null, backgroundColor)
    mainWrapper.style.height = `${wrapperHeight}px`
    mainWrapper.appendChild(absoluteWrapper)
    absoluteWrapper.appendChild(customWrapper)
    self.wrap(target, mainWrapper, customWrapper)

    window.addEventListener(
      'resize',
      function (event) {
        const innerHeight = target.clientHeight
        mainWrapper.style.height = `${innerHeight}px`
      },
      true
    )
  }

  wrap(el, wrapper, innerWrapper) {
    el.parentNode.insertBefore(wrapper, el)
    if (innerWrapper) {
      innerWrapper.appendChild(el)
    } else {
      wrapper.appendChild(el)
    }
  }
}

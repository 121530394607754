import { Modal } from 'tailwindcss-stimulus-components'

export default class extends Modal {
  static targets = ['container']
  static values = { url: String }

  connect() {
    this.bioCard = this.element.closest('.card')
    super.connect()
  }

  open(e) {
    super.open(e)
    this.removeZoomOncard()
    this.setContent()
  }

  close(e) {
    super.close(e)
    this.addZoomOncard()
  }

  removeZoomOncard() {
    if (this.hasBioCard()) this.bioCard.classList.add('card--no-zoom')
  }

  addZoomOncard() {
    if (this.hasBioCard()) this.bioCard.classList.remove('card--no-zoom')
  }

  hasBioCard() {
    return this.bioCard != null
  }

  async setContent(event) {
    if (this.hasUrlValue) this.fetchContent()
  }

  async fetchContent() {
    if (this.hasContainerTarget) {
      this.containerTarget.firstElementChild.innerHTML = this.setLoader()
      const response = await fetch(this.urlValue)
      const responseHTML = await response.text()

      this.containerTarget.firstElementChild.innerHTML = responseHTML

      let scriptElement = document.createElement('script')
      scriptElement.src = "https://secure.ewaypayments.com/scripts/eCrypt.min.js"

      this.containerTarget.firstElementChild.appendChild(scriptElement)
    }
  }

  setLoader() {
    return `<div class='loading-bar'></div>`
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'output', 'preview']

  attachImage(element) {
    this.element.querySelector('#user_profile_pic').click()
  }

  disconnect() {
    this.element.remove()
  }

  readURL() {
    let input = this.inputTarget
    let output = this.outputTarget
    let preview = this.previewTarget
    $(preview).addClass('hidden')
    $(output).removeClass('hidden')
    if (input.files && input.files[0]) {
      var reader = new FileReader()
      reader.onload = function () {
        output.src = reader.result
      }
      reader.readAsDataURL(input.files[0])
    }
  }
}

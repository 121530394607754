import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selected"]

  showDropdown() {
    if (this.element.classList.contains('dropdown--active')) {
      this.element.classList.remove('dropdown--active')
    } else {
      this.element.classList.add('dropdown--active')
      if (this.element.classList.contains('dropdown--checked')) {
        this.element.classList.remove('dropdown--checked')
      }
    }
  }

  setValue(event) {
    this.selectedTarget.innerText = event.currentTarget.innerText
    this.element.querySelector(`#${this.element.dataset.selectName}`).value = event.currentTarget.dataset.value
    this.element.classList.add('dropdown--checked')
    if (document.querySelector('#continue-to-payment')) {
      document.querySelector('#continue-to-payment').disabled = false
    }
    this.showDropdown()
  }
}
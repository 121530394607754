import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['video', 'chapterContainer']

  connect() {
    if (this.hasChapterContainerTarget && this.hasVideoTarget) {
      this.matchVideoHeight()
    }
  }

  matchVideoHeight() {
    if (window.screen.width >= 1024) {
      const height = window
        .getComputedStyle(this.videoTarget)
        .getPropertyValue('padding-bottom')
      this.chapterContainerTarget.style.height = height
    }
  }
}

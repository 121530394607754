import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['carousel', 'scroll']

  connect() {
    this.checkScroll()
  }

  scroll(event) {
    if (this.hasCarouselTarget) {
      const right = event.currentTarget.classList.contains('scroll-right')
      const left = event.currentTarget.classList.contains('scroll-left')
      if (left) this.carouselTarget.scrollLeft -= 380
      if (right) this.carouselTarget.scrollLeft += 380
    }
  }

  checkScroll() {
    if (this.hasCarouselTarget && this.hasScrollTarget) {
      if (this.carouselTarget.scrollWidth <= this.carouselTarget.offsetWidth) {
        this.scrollTarget.classList.add('hidden')
      } else {
        this.scrollTarget.classList.remove('hidden')
      }
      this.setCarouselContainerCenter()
    }
  }
  setCarouselContainerCenter() {
    if (
      window.screen.width <= 576 &&
      this.carouselTarget.children.length === 1
    ) {
      this.carouselTarget.classList.add('justify-center')
    } else if (
      window.screen.width > 576 &&
      this.carouselTarget.classList.contains('justify-center')
    ) {
      this.carouselTarget.classList.remove('justify-center')
    }
  }
}

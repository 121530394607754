import { Controller} from "stimulus";

export default class extends Controller {
  static targets = ['select', 'field', 'input']

  connect() {
    this.toggleVisibility()
  }

  toggleVisibility() {
    if (this.selectTarget.value === 'Food Allergies (Free Text)') {
      this.fieldTarget.classList.remove('hide')
    }
    else {
      this.fieldTarget.classList.add('hide')
      this.inputTarget.value = ''

      const event = new Event('change');
      this.inputTarget.dispatchEvent(event);
    }
  }
}

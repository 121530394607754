import { Controller } from 'stimulus'
import Swiper, { Navigation } from 'swiper';
import 'swiper/css/bundle';

export default class extends Controller {
  static targets = ["swiperWrapper"]

  connect() {
    this.updateSwiper()
  }

  updateSwiper() {
    const width = window.innerWidth;

    let slidesPerView = 6

    if (width < 1075) {
      slidesPerView = width < 654 ? 2 : 4;
    }

    this.alignItems(slidesPerView)

    const swiper = new Swiper('.swiper', {
      slidesPerView: slidesPerView,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      modules: [Navigation],
      observer: true,
      observeParents: true
    });
  }

  alignItems(slidesPerView) {
    const items = document.getElementsByClassName('swiper-slide')

    if (items.length < slidesPerView) {
      this.swiperWrapperTarget.classList.add('slides-centered')
    }
    else {
      this.swiperWrapperTarget.classList.remove('slides-centered')
    }
  }
}
